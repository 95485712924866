import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { ResponseBackAzure, ResponseBackAzureContrato } from '@core/models/responsehttp.model';
import { options } from '@fullcalendar/core/preact';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ModifcacionesContratoService {
  private readonly urlBase = 'contratos/ventas/registros/log';

  protected _http = inject(HttpClient);
  private readonly httpParam = new HttpParams().append('backend', 'azure');

  getModificaciones(contratoId?: string) {
    return this._http.get<ResponseBackAzure>(
      `${environment.WebApiUrlCarantyamdev}/${this.urlBase}?contratoId=${contratoId}`,
      {
        params: this.httpParam,
      }
    );
  }

  postModificaciones(body: any) {
    return this._http.post<ResponseBackAzureContrato>(`${environment.WebApiUrlCarantyamdev}/${this.urlBase}`, body, {
      params: this.httpParam,
    });
  }

  putModificaciones(body: any) {
    return this._http.put<ResponseBackAzure>(`${environment.WebApiUrlCarantyamdev}/${this.urlBase}`, body, {
      params: this.httpParam,
    });
  }

  deleteModificaciones(id: string) {
    return this._http.delete<ResponseBackAzure>(`${environment.WebApiUrlCarantyamdev}/${this.urlBase}/${id}`, {
      params: this.httpParam,
    });
  }
}
